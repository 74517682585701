import LinkComponent from '../LinkComponent/LinkComponent'
import './SearchTag.scss'

type SearchTagProps = {
  href: string
  text: string
}

export default function SearchTag({ href, text }: SearchTagProps) {
  return (
    <LinkComponent href={href} addClass="SearchTag">
      <span>{text}</span>
    </LinkComponent>
  )
}
