import LinkComponent from '../LinkComponent/LinkComponent'
import './TagComponent.scss'

export interface ITagComponent {
  link?: string
  text: string
  addClass?: string
  icon?: string
  onClick?: () => void
}

const TagComponent = ({
  link,
  text,
  addClass,
  icon,
  onClick,
}: ITagComponent) => {
  const tagContent = (
    <>
      {icon && <i className={`bx ${icon} mr-2`}></i>}
      {text}
    </>
  )

  const customTagClass = `TagComponent ${addClass ? addClass : ''}`

  return link ? (
    <LinkComponent addClass={customTagClass} href={link}>
      {tagContent}
    </LinkComponent>
  ) : (
    <span className={customTagClass} onClick={onClick}>
      {tagContent}
    </span>
  )
}

export default TagComponent
