import LinkComponent from '../LinkComponent/LinkComponent'
import './style.scss'

interface IBuildWithPLSectionProps {
  title: string
  buttons: {
    text: string
    link: string
  }[]
  addClass?: string
}

const BuildWithPLSection = ({
  title,
  buttons,
  addClass = '',
}: IBuildWithPLSectionProps) => {
  return (
    <section className={`BuildWithPLSection ${addClass}`}>
      <div className="BuildWithPLSection__container">
        <h2>{title}</h2>
        <div className="BuildWithPLSection__buttons">
          {buttons.map((button, index) => {
            return (
              <LinkComponent
                key={`button-${index}`}
                href={button.link}
                addClass={`BuildWithPLSection__button ${
                  index % 2 === 0
                    ? 'Button__primary--white'
                    : 'Button__secondary--white'
                }`}
              >
                {button.text}
              </LinkComponent>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default BuildWithPLSection
